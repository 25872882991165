.icon {
  visibility: hidden;
  transition: 0.2s ease-in;
}

.golink {
  transition: 0.2s ease-in;
}

.golink:hover .icon {
  visibility: visible;
  margin-left: 7px;
}
