* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

::-webkit-scrollbar {
  width: 10px;
  background: lightgray;
  display: block;
}

.profile-chat-wrapper {
  margin: 6.5rem auto 4rem auto;
}

.chat-wrapper {
  width: 99%;
  margin: 1rem auto 2rem auto;
  background: #fff;
  border-radius: 8px;
}

.chat-wrapper .chat-container {
  width: 100%;
  /* height: 64vh; */
  height: 615px;
  display: flex;
  border-top: 1px solid #e7e7e7;
}

.chat-wrapper .chat-header {
  display: flex;
  width: 100%;
  padding: 0rem 0rem;
}

.chat-header .all_conversations {
  flex: 0 0 20.64%;
  display: flex;
  justify-content: space-between;
  position: relative;
  align-items: center;
  padding: 0.9rem;
  cursor: pointer;
}

.search_inbox {
  width: 100%;
  height: 100%;
  z-index: 9999;
  position: absolute;
}

.search_inbox input {
  width: 90%;
  height: 100%;
  outline: none;
  border: none;
  padding-left: 1rem;
}

.search_inbox #search {
  position: absolute;
  right: 15%;
  top: 45%;
}

.all_conversations .archive_menu {
  width: 15rem;
  height: auto;
  background: #fff;
  border-radius: 5px;
  position: absolute;
  top: 72%;
  z-index: 9999;
  padding-bottom: 1rem;
}

.all_conversations .popup_menu {
  width: 15rem;
  height: auto;
  background: #fff;
  border-radius: 5px;
  position: absolute;
  top: 72%;
  right: -2%;
  z-index: 9999;
  padding-bottom: 1rem;
  padding-top: 0.5rem;
}

.archive_menu li {
  display: block;
  font-size: 1rem;
  padding: 0.5rem 1rem;
  cursor: pointer;
}

.popup_menu li {
  display: flex;
  font-size: 1rem;
  padding: 0.6rem 1rem;
  cursor: pointer;
}

.popup_menu li span {
  display: flex;
  gap: 5px;
  font-size: 1.1rem;
  align-items: center;
  justify-content: center;
  color: #555;
}

.popup_menu li:hover {
  background-color: #f7f7f7;
}

.archive_menu li:hover {
  background-color: #f7f7f7;
}

.chat-header .all_conversations div:nth-child(1) {
  display: flex;
  align-items: center;
  gap: 4px;
}

.all_conversations h3 {
  color: #62646a;
  font-weight: 500;
}

.all_conversations .arrow_down {
  font-size: 1.1rem;
  cursor: pointer;
}

.all_conversations .search_icon {
  font-size: 1.1rem;
  color: #62646a;
  font-weight: 700;
  cursor: pointer;
}

.chat-header .header_center {
  flex: 1;
  padding: 0.9rem;
  border-right: 1px solid #e7e7e7;
}

.header_center .name h3,
.header_right .name h3 {
  color: #404145;
  font-weight: 900;
  text-decoration: underline;
  font-size: 1rem;
}

.chat-header .header_center .inner,
.chat-header .header_right .inner {
  display: flex;
  gap: 10px;
  align-items: center;
  cursor: pointer;
}

.chat-header .header_center img,
.chat-header .header_right img {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
}

.chat-header .header_right {
  flex: 0 0 20.64%;
  padding: 0.9rem;
  border-right: 1px solid #e7e7e7;
}

.chat-container .left_side {
  flex: 0 0 20.63%;
  height: 100%;
  border-right: 1px solid #e7e7e7;
  overflow-y: auto;
  flex-shrink: 0;
}

.left_side .chat_list {
  width: 100%;
  height: 100%;
}

.left_side ul {
  overflow-y: auto;
  height: 100%;
}

.chat_list .chat_item {
  display: flex;
  align-items: center;
  gap: 14px;
  position: relative;
  padding: 1rem 0.6rem;
  border-bottom: 1px solid #e7e7e7;
  cursor: pointer;
}

.chat_list .chat_item:hover {
  background-color: #f7f7f7;
}

.chat_item .image {
  position: relative;
}

.chat_item .image img {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
}

.chat_item .username_msg {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.username_msg h3 {
  font-weight: 700;
  color: #222325;
  font-size: 1rem;
}

.username_msg h4 {
  font-weight: 400;
  color: #555;
  font-size: 0.9rem;
}

.chat_item .time {
  position: absolute;
  top: 25%;
  right: 5%;
}

.chat_item .turn {
  position: absolute;
  bottom: 5%;
  right: 5%;
}

.chat_item .turn h3 {
  color: #1dbf73;
  font-size: 0.9rem;
  font-weight: 900;
}

.chat_item .time h3 {
  color: #95979d;
  font-size: 0.9rem;
}

.chat_item .unread_messages {
  width: 1.3rem;
  height: 1.3rem;
  border-radius: 50%;
  background-color: #1dbf73;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -5px;
  right: -2px;
}

.seen_ticks {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin-bottom: 10px;
}

.seen_ticks span > * {
  font-size: 1.3rem;
}

.unread_messages h3 {
  font-size: 0.9rem;
}

.chat-container .chat_section {
  flex: 0 0 58.68%;
  height: 100%;
  padding: 1rem 1.4rem;
  border-right: 1px solid #e7e7e7;
  position: relative;
  flex-grow: 1;

  display: flex;
  flex-direction: column;
}

.chat_section .chat_message_wrapper ul {
  max-height: 100%;
}

.chat_section ul::-webkit-scrollbar {
  width: 9px;
}

.chat_section .subscription_member {
  width: 70%;
  padding: 1rem;
  border-radius: 2rem;
  text-align: center;
  margin: auto;
}

.chat_section .message_box {
  /* position: absolute;
  bottom: 8%;
  left: 0;
  right: 0; */
  /* margin: auto; */
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.unarchive_chat {
  position: absolute;
  bottom: 5%;
  left: 0;
  right: 0;
  margin: auto;
  display: flex;
  flex-direction: column;
  width: 95%;
  z-index: 999;
  padding: 0.4rem 1rem;
  background-color: rgba(3, 46, 66, var(--tw-bg-opacity));
  font-weight: 400;
  border-radius: 0.5rem /* 8px */;
  --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  --tw-text-opacity: 1;
  color: rgba(253, 230, 138, var(--tw-text-opacity));
}

.unarchive_chat .heading {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.8rem;
  margin-bottom: 10px;
}

.unarchive_chat .heading span:nth-child(1) {
  font-weight: 700;
  font-size: 1.2rem;
}

.unarchive_chat .heading span:nth-child(1) {
  font-size: 1rem;
  font-style: italic;
}

.unarchive_chat .descrip span {
  font-size: 0.9rem;
  color: #555;
}

.message_box textarea {
  height: 3rem;
  border: 1px solid #e7e7e7;
  box-shadow: 0 1px 5px 0 rgb(0 0 0 / 10%);
}

.chat_section .send-btn {
  cursor: pointer;
}

.send-btn div,
button {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3px;
}

.send-btn button {
  color: #1dbf73;
  font-size: 1rem;
  font-weight: 700;
}

.send-btn div > * {
  font-weight: 700;
  font-size: 1rem;
  color: #1dbf73;
}

.chat_message_wrapper {
  flex-grow: 1;
  margin-bottom: 1rem;
  overflow: auto;
}

.chat_message_wrapper ul li {
  display: flex;
  margin-bottom: 2rem;
  gap: 11px;
  padding-right: 2rem;
}

.chat_message_wrapper li .user_info {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  border-radius: 0.8rem;
  padding: 0.4rem 1.5rem;
  word-wrap: break-word;
  width: 50%;
  height: auto;
  gap: 3px;
}

.borderTopRight {
  border-top-right-radius: 0 !important;
}

.borderTopLeft {
  border-top-left-radius: 0 !important;
}

.grayBg {
  background: #f9f9fb;
}

.grayColor {
  color: rgb(225, 222, 222) !important;
}

.lightBlack {
  color: #555 !important;
}

.white {
  color: #f7f7f7 !important;
}

.greenBg {
  background: #8098d1;
  color: #fff !important;
}

.chat_message_wrapper li .user_info h3 {
  color: #222325;
  font-weight: 700;
  margin: 0;
}

.chat_message_wrapper li .user_info h4 {
  color: #62646a;
  font-weight: normal;
  font-size: 14px;
  line-height: 145%;
  letter-spacing: 0.3px;
  margin: 0;
}

.chat_message_wrapper ul li img {
  width: 2.6rem;
  height: 2.6rem;
  border-radius: 50%;
}

.chat-container .right_side {
  flex: 0 0 20.64%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 6px;
  padding: 1.8rem 1rem;
  border-right: 2px solid #e7e7e7;
}

.online_status {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.online_status span {
  width: 0.9rem;
  height: 0.9rem;
  background: #1dbf73;
  border-radius: 50%;
}

.online_status h3 {
  color: #222325;
  font-weight: 700;
}

.user_details {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 1rem;
}

.user_details .item {
  display: flex;
  justify-content: space-between;
}

.user_details .item h3 {
  color: #62646a;
  font-weight: 600;
}

.user_details .item h4 {
  /* color: #000; */
  color: #62646a;
  font-weight: 400;
}

.right_side .footer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-top: 2rem;
}

.right_side .footer > * {
  font-size: 1.3rem;
  color: #62646a;
  cursor: pointer;
}

.right_side .footer span svg {
  color: #62646a !important;
}

.right_side .footer > *:hover {
  color: #000;
}

@media screen and (max-width: 1280px) {
  .chat-container .left_side {
    flex: 0 0 20.93%;
  }
  .chat-container .chat_section {
    flex: 0 0 58.1%;
  }
}

@media screen and (max-width: 1228px) {
  .chat-header .header_right {
    display: none !important;
    flex: 0 0 0% !important;
    border: none;
  }

  .chat-container .chat_section {
    flex: 0 0 43%;
  }
  .chat-header .all_conversations {
    flex: 0 0 35%;
  }
  .chat-container .left_side {
    flex: 0 0 35.55%;
  }

  .chat-header .header_center {
    border-right: 0px;
    flex: 0 0 100%;
  }
}

@media screen and (max-width: 800px) {
  .chat-container .chat_section {
    display: none;
    flex: 0 0 100%;
  }

  .chat-header .header_center {
    flex: 0 0 0%;
    display: none !important;
  }
  .chat-header .all_conversations {
    flex: 0 0 100%;
    border-right: none;
  }
  .chat-container .left_side {
    flex: 0 0 100%;
  }
  .chat-container .chat_section {
    flex: 0 0 100%;
  }
  .send-btn button {
    margin-top: 5px;
  }
}

.active_chat_section {
  display: flex !important;
}

@media screen and (max-width: 1024px) {
  .chat-container .right_side {
    display: none !important;
    flex: 0 0 0%;
    border-right: 2px solid #e7e7e7;
  }

  .chat-container .chat_section {
    flex: 1 !important;
  }
}

/* Auto resizing text area */
#autoresizing {
  display: block;
  overflow: hidden;
  resize: none;
  max-height: 160px !important;
}
