.footerLink {
    color: #Fff;
    transition: .2s ease;
}

.footerLink:hover {
    color: #E7B93A;
}

.navlink {
    color: rgb(129, 126, 127);
}