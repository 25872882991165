/* ./src/index.css */

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.hero {
  background-image: url("./images/home.jpg");
  background-size: cover;
  height: 100vh;
}

.overlay {
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0.801),
    rgba(197, 197, 197, 0)
  );
  z-index: 10;
}

select option {
  margin: 40px;
  background: #e7b93a;
  padding: 0px 8px;

  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
}
.select option {
  background: none !important;
}

select option:hover {
  background: #032e42 !important;
}

.rc-anchor-light.rc-anchor-normal {
  border: none !important;
}

.rc-anchor {
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiMenu-paper.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPopover-paper.css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper {
  height: 100px !important;
}

.profile_container {
  left: 41%;
  bottom: -90px;
  position: absolute;
}

.likeup {
  height: 20px;
  width: 20px;
  transition: 0.2s ease-in;
}

.likeup:hover {
  height: 25px;
  width: 25px;
}

.css-jcn4dz-MuiBadge-badge {
  z-index: 99 !important;
}

.notice .carousel-indicators {
  display: none !important;
}

@media only screen and (max-width: 768px) {
  .profile_container {
    left: 38%;
    bottom: -90px;
    position: absolute;
  }
}

@media only screen and (max-width: 500px) {
  .profile_container {
    left: 32%;
    bottom: -60px;
    position: absolute;
  }
}

.optionListContainer {
  background: #e7b93a !important;
}

.StripeCheckout {
  background: #10b981 !important;
  padding: 8px 25px !important;
}

.StripeCheckout span {
  background: none !important;
  border: none !important;
  color: white !important;
  background-image: none !important;
  font-weight: bold !important;
  font-size: large !important;
}

.my-2.shadow-md.accordion-item {
  border-radius: 10px !important;
}

.accordion-header {
  border-radius: 10px !important;
  overflow-x: hidden;
  overflow-y: visible;
}

body {
  background: #eff6ff;
}

.new_animation {
  animation: mymove 5s infinite;
}

@keyframes mymove {
  0% {
    top: 0;
    left: 0px;
    background: #e7b93a5d;
  }

  25% {
    top: 0;
    left: 30%;
    background: #032e4273;
  }

  50% {
    top: 0;
    left: 60%;
    background: #e7b93a5d;
  }

  75% {
    top: 0;
    left: 70%;
    background: #032e4273;
  }

  100% {
    top: 0;
    left: 0px;
    background: #e7b93a5d;
  }
}

.h-m {
  height: 72%;
}
.bg-a {
  background-color: #f7f7f7;
}
.h-max {
  height: max-content !important;
}
